import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter as Router} from 'connected-react-router'
import { history } from './store'

// import { renderRoutes } from 'react-router-config';

import './App.scss';

//import Home from './views/Home'
import Auth from './views/Auth/Auth'
import AdminAuth from './views/Admin/Auth'
//import Submission  from './views/Submission'

import PrivateRoute from "./components/private-route/PrivateRoute";

import { useStickyState } from './utils'

//import * as Sentry from '@sentry/browser';

//Sentry.init({dsn: "https://b528c0094e804f1880b426fb96b68ec0@sentry.io/1793162"});

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const ContentLayout = React.lazy(() => import('./containers/DefaultLayout/ContentLayout'));
const ModalLayout = React.lazy(() => import('./containers/DefaultLayout/ModalLayout'));

// Pages
//const NewUser  = React.lazy(() => import('./views/Pages/NewUser') )
//const Signin = React.lazy(() => import('./views/Pages/Signin'));
//const Signin2 = React.lazy(() => import('./views/Pages/Signin/Signin2'));
//const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Sheet = React.lazy(() => import('./views/Content'));
const Grid = React.lazy(() => import('./views/Content/Grid'));

const App = (props) => {
  const [
    count,
    setCount
  ] = useStickyState(0, "count");

    return (
       <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            {/*<Route exact path="/new-user" name="New User Registration" render={props => <NewUser {...props}/>} />
            //<Route exact path="/signin" name="Login Page" render={props => <Signin {...props}/>} />
            //<Route exact path="/signin2" name="Signin2 Page" render={props => <Signin2 {...props}/>} />
            //<Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />*/}
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
            <Route path="/auth" component={Auth} />
            <Route path="/auth/admin" component={AdminAuth} />
            {/*<Route path="/user" name="User" render={props => <Submission {...props} formId={'user'} />} />
            <Route path="/group" name="Group" render={props => <Submission {...props} formId={'group'} />} />*/}
            <Route path="/content/course/*/preview" name="Content" component={DefaultLayout}/>
            <Route path="/content/course/*/edit" name="Content" component={DefaultLayout}/>
            <Route path="/content/course/*/print" name="Content" component={ContentLayout}/>
            <Route path="/content/course/*/approve" name="Content" component={ContentLayout}/>
            <Route path="/content/course/*/play" name="Content" component={ContentLayout}/>
            <Route path="/content/course/*/design" name="Content" component={ContentLayout}/>
            <Route path="/course/approve" name="Content" component={ContentLayout}/>
            <Route path="/content/sheets/" name="Sheet" component={Sheet}/>
            <Route path="/content/grid/" name="Grid" component={Grid}/>
            <PrivateRoute path="/content/*" name="Content" component={DefaultLayout}/>
            <PrivateRoute path="/ui/*" name="UI" component={ModalLayout}/>
            <PrivateRoute path="/" name="Home" component={DefaultLayout}/>
          </Switch>
        </React.Suspense>
      </Router>
    );
}

export default App;
