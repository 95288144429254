import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { selectRoot } from "react-formio";

const PageLoad = React.lazy(() => import('../../views/Pages/PageLoad'));
//const Page404 = React.lazy(() => import('../../views/Pages/Page404'));
//const Page500 = React.lazy(() => import('../../views/Pages/Page500'));

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        auth.isActive === true ? (
          <PageLoad {...props} />
        ) : (
          auth.authenticated === true ? (
            <Component {...props} />
          ) : (
            <Redirect to="/auth" />
          )
        )
      )
      }
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: selectRoot('auth', state)
});

const mapDispatchToProps = () => {
  return {};
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute));
