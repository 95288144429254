import React, {Component} from 'react';
import Login from './Login';
import Account from './Account';
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import {Route, Link, Switch} from "react-router-dom";
//import {AppConfig} from '../../config';
import { AppNavbarBrand } from "@coreui/react";
import { connect } from "react-redux";
import { selectRoot } from "react-formio";

import logo from "../../assets/img/brand/edifyd_icon-green-medium-clear.png";

import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
} from "reactstrap";

import { getGlobalState } from '../../utils';
import { useIsMountedRef, useStickyState } from '../../utils'

const AuthPage = (props) => {

  const isMountedRef = useIsMountedRef();

  const [
    account,
    setAccount
  ] = useStickyState({}, "account");

  const {location} = props;

  if (props.auth && props.auth.authenticated) {
    return window.location.href = '#/';
  }

  const switchAccount = (ev) => {
    setAccount(false);
    return window.location.href = '#/auth';
  }

  if (props.location.pathname === '/forgotpassword') {
    return (<ForgotPassword />);
  }

  let match;
  if (window.location.hash) {
    match = window.location.hash.match(/.*auth\/(.*)/);
  }

  if (!account || !account.domain || (match && (match[1] != account.account))) {
    return (<Account setAccount={setAccount} />);
  }

    return (
      <div id="signin2-background-image">
        <div id="signin2-text" className="d-none d-md-block">
          <p id="signin2-text1">Get Onboarded and Work.</p>
          <p id="signin2-text2">
              <span>
                <AppNavbarBrand
                  id="copyright-logo"
                  full={{
                    src: logo,
                    width: 25,
                    height: 25,
                    alt: "Edifyd Logo"
                  }}
                />
              </span>
            Copyright © <span>2021</span>
          </p>
        </div>
        <Container>
          <Row>
            <Col className="col-md-5 offset-md-8">
              <CardGroup>
                <Card id="signin2-card" className="p-4">
                  <CardBody>
                    <AppNavbarBrand
                      id="signin2-logo"
                      full={{
                        src: logo,
                        width: 40,
                        height: 40,
                        alt: "Edifyd Logo"
                      }}
                    />
                    <h3>Welcome to {account.name}</h3>
                    <h4>Sign in</h4>

                    <Switch>
                      <Route path="/forgotpassword" component={ForgotPassword} />
                      <Route path="/auth/register" component={Register} />
                      <Route path="/auth" component={Login} />
                    </Switch>

                    <div id="signin2-create-forgot-btns">
                      { /*<p><Link to="/auth/forgot"><i className="fa fa-exclamation-triangle" /> Forgot username?</Link></p> */ }
                      <p><Link to="/forgotpassword"><i className="fa fa-exclamation-triangle" /> Forgot password?</Link></p>
	                    { /*<p><Link to="/register"><i className="fa fa-plus" /> First time user?</Link></p> */ }
                      <p><a className="fa fa-plus" onClick={switchAccount}> Another account?</a></p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>)
}

const Auth = class extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {location} = this.props;

    return (<AuthPage {...this.props} />);
  }
}

const mapStateToProps = (state) => {
  return {
    auth: selectRoot('auth', state)
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
