import { combineReducers } from "redux";
//import authReducer from "./authReducer";
import dataReducer from "./dataReducer";
import filterReducer from './filterReducer';
import modelReducer from './modelReducer';
import { auth } from 'react-formio';
import { connectRouter } from 'connected-react-router'
import { createHashHistory as createHistory } from 'history'

export const history = createHistory()

const reducers = {
  auth: auth(),
  data: dataReducer,
  filter: filterReducer,
  router: connectRouter(history)
}

const summary = {...reducers, ...modelReducer};

export default combineReducers(summary);
