import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-formio';
import { push } from 'connected-react-router'

import {AppConfig, AuthConfig} from '../../config';

import { setUser, saveSubmission } from 'react-formio';
import {Route, Link} from "react-router-dom";

import { AppNavbarBrand } from "@coreui/react";
import logo from "../../assets/img/brand/edifyd_icon-green-medium-clear.png";

import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
} from "reactstrap";

import _get from 'lodash/get';

import { useIsMountedRef, useStickyState } from '../../utils'

const AppForm = (props) => {
  const isMountedRef = useIsMountedRef();

  const [
    count,
    setCount
  ] = useStickyState(0, "count");

  const setAccountState = (acc) => {
    props.setAccount(acc);
  }

  //React.useEffect(() => {
  //}, [account, isMountedRef]);

  var options = {
      'hooks': {
        'beforeSubmit': async (submission, cb) => {
          const wait = () => {
            return new Promise((resolve, reject) => {
              props.dispatch(saveSubmission('account/login', submission, AppConfig.runnerUrl + 'edifyd/account/login', (err, res) => {
                if (err) {
                  alert('Sorry the account ' + submission.data.accountId + ' is Invalid');
                  return resolve();
                }
                var data = _get(res, '[0].data');
                if (data) {
                  setAccountState(data);
                  resolve(data);
                }
              }));
            });
          }
          const account = await wait();
          if (isMountedRef.current) {
            setAccountState(account);
            setCount(count + 1);
            //props.update(account);
          }
        },
      }
    };

  var onSubmit = (submission) => {
      //dispatch(setUser(submission));
      //dispatch(push(AuthConfig.authState));
    }

  let account;
  let submission = {};
  if (window.location.hash) {
    var match = window.location.hash.match(/.*auth\/(.*)/);
    if (match && match[1] && match[1] !== 'login') {
      account = match[1];
      submission = {
        data: {
          accountId: account
        }
      }
      props.dispatch(saveSubmission('account/login', submission, AppConfig.runnerUrl + 'edifyd/account/login', (err, res) => {
        var data = _get(res, '[0].data');
        if (data) {
          setAccountState(data);
          window.location.href = '#/auth';
           //window.location.reload();
        }
      }));
    }
  }

  return (
    <>
    <Form {...props} submission={submission} options={options} onSubmit={onSubmit} />
    </>
  );
}

const Account = class extends Component {

  render() {
    return (
      <div id="signin2-background-image" className="auth-account">
        <div id="signin2-text" className="d-none d-md-block">
          <p id="signin2-text1">Get Onboarded and Work.</p>
          <p id="signin2-text2">
              <span>
                <AppNavbarBrand
                  id="copyright-logo"
                  full={{
                    src: logo,
                    width: 25,
                    height: 25,
                    alt: "Edifyd Logo"
                  }}
                />
              </span>
            Copyright © <span>2021</span>
          </p>
        </div>
        <Container>
          <Row>
            <Col className="col-md-12">
              <CardGroup>
                <Card id="signin2-card" className="p-4">
                  <CardBody>
                    <AppNavbarBrand
                      id="signin2-logo"
                      full={{
                        src: logo,
                        width: 40,
                        height: 40,
                        alt: "Edifyd Logo"
                      }}
                    />
                    <AppForm {...this.props} />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  onRender = (test) => {
  }
}

const mapStateToProps = () => {
  return {
    src: AppConfig.projectUrl + AuthConfig.accountLogin.form,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch: dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account)
