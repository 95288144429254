import { FILTER_TYPE, FILTER_SEARCH } from "../actions/types";
const initialState = {
  type: [],
  search: []
};

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case FILTER_TYPE:
      return {
        ...state,
        type: action.payload
      };
    case FILTER_SEARCH:
      return {
        ...state,
        search: action.payload
      };

    default:
      return state;
  }
}
