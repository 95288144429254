import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-formio';
import { push } from 'connected-react-router'
import {AppConfig, AuthConfig, AppModule} from '../../config';
import { setUser, saveSubmission, getSubmissions } from 'react-formio';

import { AppNavbarBrand } from "@coreui/react";
import logo from "../../assets/img/brand/edifyd_icon-green-medium-clear.png";

import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
} from "reactstrap";

import _get from 'lodash/get';

const ForgotPassword = class extends Component {
  render() {
    return (
      <div id="signin2-background-image" className="auth-account">
        <div id="signin2-text" className="d-none d-md-block">
          <p id="signin2-text1">Get Onboarded and Work.</p>
          <p id="signin2-text2">
              <span>
                <AppNavbarBrand
                  id="copyright-logo"
                  full={{
                    src: logo,
                    width: 25,
                    height: 25,
                    alt: "Edifyd Logo"
                  }}
                />
              </span>
            Copyright © <span>2021</span>
          </p>
        </div>
        <Container>
          <Row>
            <Col className="col-md-12">
              <CardGroup>
                <Card id="signin2-card" className="p-4">
                  <CardBody>
                    <AppNavbarBrand
                      id="signin2-logo"
                      full={{
                        src: logo,
                        width: 40,
                        height: 40,
                        alt: "Edifyd Logo"
                      }}
                    />
      <Form {...this.props} onRender={this.onRender} />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  onRender = (test) => {
  }
}

const mapStateToProps = () => {
  return {
    //src: (AppConfig.runnerUrl ? AppConfig.runnerUrl + 'formapi' : AppConfig.projectUrl) + '/' + AuthConfig.login.form,
    src: AppConfig.projectUrl + AuthConfig.forgotPassword.form,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    options: {
      'hooks': {
        'beforeSubmit': (submission, cb) => {
          dispatch(saveSubmission('app/login', submission, AppConfig.runnerUrl + 'edifyd/user/forgotpassword', (err, res) => {
            console.log(res);
            if (err) {
              return cb(err.error || err.err || 'Server: Unable to log in');
            }

            if (res && res.length) {
              var body = res[0].data;
              if (body && body.success == 'ok') {
                alert('An reset password link has been sent to your email ' + body.doc.email);
                window.location.href = '#/auth';
                return cb();
              }
            }

            return cb(err.error || 'Unknown error');
          }));
        },
      }
    },
    authUrl: AppConfig.runnerUrl + 'edifyd/user',
    onError: (a, b) => {
    },
    onAttach: (submission) => {
      console.log('onAttach');
    },
    onRender: (submission) => {
      console.log('onRender');
    },
    onFormLoad: (submission) => {
      console.log('onFormLoad');
    },
    onSubmitButton: (submission, b) => {
      console.log('onSubmitButton');
    },
    onSubmit: (submission) => {
      console.log('onSubmit');
    },
    onSubmitDone: (submission) => {
      console.log('onSubmitDone');
      console.log(submission);
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword)
