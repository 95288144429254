const dev = {
  projectUrl: 'https://api-dev.cloud.edifyd.com',
  apiUrl: 'https://api-dev.cloud.edifyd.com',
  appUrl: 'https://api-dev.cloud.edifyd.com',
  runnerUrl: 'https://api-dev.cloud.edifyd.com/runner/'
}

const staging = {
  projectUrl: 'https://api-dev.cloud.edifyd.com',
  apiUrl: 'https://api-dev.cloud.edifyd.com',
  appUrl: 'https://api-dev.cloud.edifyd.com',
  runnerUrl: 'https://api-dev.cloud.edifyd.com/runner/'
}

const prod = {
  projectUrl: 'https://api.cloud.edifyd.com',
  apiUrl: 'https://api.cloud.edifyd.com',
  appUrl: 'https://api.cloud.edifyd.com',
  runnerUrl: 'https://api.cloud.edifyd.com/runner/'
}

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod : process.env.REACT_APP_STAGE === 'staging'
  ? staging : dev;

export const AppConfig = {
  contentUrl: 'https://app-dev.cloud.edifyd.com',
  ...config
};

export const AuthConfig = {
  anonState: '/auth',
  authState: '/',
  login: {
    form: '/app/login'
  },
  accountLogin: {
    form: '/account/login'
  },
  adminLogin: {
    form: '/user/login'
  },
  register: {
    form: '/user/register'
  }
};

export const AppModule = {
  "email": {
    formId: "email",
    formUrl: config.runnerUrl + 'formapi/' + "/email",
    //apiUrl: config.runnerUrl + 'edifyd/' + "/email",
    state: "email"
  },
  "person": {
    formId: "person",
    formUrl: config.runnerUrl + 'formapi/' + "/person",
    //apiUrl: config.runnerUrl + 'edifyd/' + "/person",
    state: "person"
  },
  "user/emails": {
    formId: "user/emails",
    formUrl: config.runnerUrl + 'formapi/' + "/user/emails",
    state: "user/emails"
  },
  "user/courses": {
    formId: "user/courses",
    formUrl: config.runnerUrl + 'formapi/' + "/user/courses",
    state: "user/courses"
  },
  "user/groups": {
    formId: "user/groups",
    formUrl: config.runnerUrl + 'formapi/' + "/user/groups",
    state: "user/groups"
  },
  "user/course_links": "user/course_links",
  "enrolment": "enrolment",
  "user": "user",
  "admin/user": {
    formId: "admin/user",
    formUrl: config.runnerUrl + 'formapi/' + "/admin/user",
    state: "admin/user",
    config: 'settings',
  },
  "userLogin": "userLogin",
  "userRegister": "userRegister",
  "adminLogin": "adminLogin",
  "company": "company",
  "group": {
    formId: "group",
    formUrl: config.runnerUrl + 'formapi/' + "/group",
    state: "group"
  },
  "group/courses": {
    formId: "group/courses",
    formUrl: config.runnerUrl + 'formapi/' + "/group/courses",
    state: "group/courses"
  },
  "group/users": {
    formId: "group/users",
    formUrl: config.runnerUrl + 'formapi/' + "/group/users",
    state: "group/users"
  },
  "group/managers": {
    formId: "group/managers",
    formUrl: config.runnerUrl + 'formapi/' + "/group/managers",
    state: "group/managers"
  },
  "group/incomplete": {
    formId: "group/incomplete",
    formUrl: config.runnerUrl + 'formapi/' + "/group/incomplete",
    state: "group/incomplete"
  },
  "group/results": {
    formId: "group/results",
    formUrl: config.runnerUrl + 'formapi/' + "/group/results",
    state: "group/results"
  },
  "level": "level",
  "roles": "roles",
  "peopleCategory": {
    formId: "people/category",
    state: "peopleCategory",
  },
  "complianceWwcc": {
    formId: "compliance/wwcc",
    state: "complianceWwcc",
  },
  "compliance/wwcc": "compliance/wwcc",
  "complianceFirstAid": "complianceFirstAid",
  "role": "role",
  "policy": "policy",
  "policyGroup": "policyGroup",
  "account": "account",
  "induction": {
    formId: "induction",
    formUrl: config.runnerUrl + 'formapi/' + "/induction",
    state: "induction",
    config: 'reporting',
  },
  "certification": "certification",
  "selfRegistration": "selfRegistration",
  "peopleCategory": "peopleCategory",
  "settings": "settings",
  "settings/quiz": {
    formId: "settings/quiz",
    formUrl: config.runnerUrl + 'formapi/' + "/settings/quiz",
    state: "settings/quiz",
    config: 'settingsQuiz',
  },
  "settings/certificate": {
    formId: "settings/certificate",
    formUrl: config.runnerUrl + 'formapi/' + "/settings/certificate",
    state: "settings/certificate",
    config: 'settingsCertificate',
  },
  "wwccCertification": "wwccCertification",
  "firstAidCertification": "firstAidCertification",
  "complianceFoodSafetySupervisor": "complianceFoodSafetySupervisor",
  "foodSafetySupervisorCertification": "foodSafetySupervisorCertification",
  "contract": "contract",
  "templates": "templates",
  "hrTemplate": "hrTemplate",
  "policyType": "policyType",
  "dataSync": "dataSync",
  "programType": "programType",
  "program": {
    formId: "program",
    formUrl: config.runnerUrl + 'formapi/' + "/program",
    state: "program"
  },
  "program/courses": {
    formId: "program/courses",
    formUrl: config.runnerUrl + 'formapi/' + "/program/courses",
    state: "program/courses",
    config: 'course',
  },
  "program/results": {
    formId: "program/results",
    formUrl: config.runnerUrl + 'formapi/' + "/program/results",
    state: "program/results",
    config: 'program',
  },
  "program/incomplete": {
    formId: "program/incomplete",
    formUrl: config.runnerUrl + 'formapi/' + "/program/incomplete",
    state: "program/incomplete"
  },
  "content/template": "content/template",
  "content/course": {
    formId: "content/course",
    formUrl: config.runnerUrl + 'formapi/' + "/content/course",
    state: "content/course"
  },
  "content/file": {
    formId: "content/file",
    formUrl: config.runnerUrl + 'formapi/' + "/content/file",
    state: "content/file"
  },
  "content/slidedoc": "content/slidedoc",
  "content/assets": "content/assets",
  "pathway": "pathway",
  "course": {
    formId: "course",
    formUrl: config.runnerUrl + 'formapi/' + "/course",
    state: "course"
  },
  "admin/course": {
    formId: "admin/course",
    formUrl: config.runnerUrl + 'formapi/' + "/course",
    state: "admin/course"
  },
  "program/users": {
    formId: "program/users",
    formUrl: config.runnerUrl + 'formapi/' + "/course/users",
    state: "program/users"
  },
  "program/results": {
    formId: "program/results",
    formUrl: config.runnerUrl + 'formapi/' + "/program/results",
    state: "program/results"
  },
  "course/users": {
    formId: "course/users",
    formUrl: config.runnerUrl + 'formapi/' + "/course/users",
    state: "course/users"
  },
  "course/groups": {
    formId: "course/groups",
    formUrl: config.runnerUrl + 'formapi/' + "/course/groups",
    state: "course/groups"
  },
  "course/results": {
    formId: "course/results",
    formUrl: config.runnerUrl + 'formapi/' + "/course/results",
    state: "course/results"
  },
  "course/incomplete": {
    formId: "course/incomplete",
    formUrl: config.runnerUrl + 'formapi/' + "/course/incomplete",
    state: "course/incomplete"
  },
  "ui/program/file": {
    formId: "ui/program/file",
    formUrl: config.runnerUrl + 'formapi/' + "/course",
    state: "ui/program/file"
  },
  "ui/program/manage": {
    formId: "ui/program/manage",
    formUrl: config.runnerUrl + 'edifyd/' + "/program/manage",
    state: "ui/program/manage"
  },
  "ui/program/group": {
    formId: "ui/program/group",
    formUrl: config.runnerUrl + 'edifyd/' + "/program/group",
    state: "ui/program/group"
  },
  "ui/group/user": {
    formId: "ui/group/user",
    formUrl: config.runnerUrl + 'edifyd/' + "/group/user",
    state: "ui/group/user"
  },
  "ui/group/program": {
    formId: "ui/group/program",
    formUrl: config.runnerUrl + 'edifyd/' + "/program/group",
    state: "ui/group/program"
  },
  "ui/acl/files": {
    formId: "ui/acl/files",
    formUrl: config.runnerUrl + 'edifyd/' + "/acl/files",
    state: "ui/acl/files"
  },
  "ui/course/sync": {
    formId: "ui/course/sync",
    formUrl: config.runnerUrl + 'edifyd/' + "/course/sync",
    state: "ui/course/sync"
  },
  "course/approve": {
    formId: "course/approve",
    formUrl: config.runnerUrl + 'edifyd/' + "/course/approve",
    state: "course/approve"
  },
  "ui/user/program": {
    formId: "ui/user/program",
    formUrl: config.runnerUrl + 'edifyd/' + "/course/approve",
    state: "ui/user/program"
  },
  "ui/user/group": {
    formId: "ui/user/group",
    formUrl: config.runnerUrl + 'edifyd/' + "/course/approve",
    state: "ui/user/group"
  },
  "ui/course/approve": {
    formId: "ui/course/approve",
    formUrl: config.runnerUrl + 'edifyd/' + "/course/approve",
    state: "ui/course/approve"
  },
  "ui/training/video": {
    formId: "ui/training/video",
    formUrl: config.runnerUrl + 'edifyd/' + "/course/video",
    state: "ui/training/video"
  },
  "ui/user/redo": {
    formId: "ui/user/redo",
    formUrl: config.runnerUrl + 'edifyd/' + "/user/redo",
    state: "ui/user/redo"
  },
  "ui/user/password": {
    formId: "ui/user/password",
    formUrl: config.runnerUrl + 'edifyd/' + "/user/password",
    state: "ui/user/password"
  },
}
