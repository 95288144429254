import React, { Component } from 'react';
import {Components} from 'formiojs';
import _get from 'lodash/get';

export const getComponentDefaultColumn = (component) => ({
  component: Components.create(component, null, null, true),
  key: `data.${component.key}`,
  sort: true,
  title: component.label || component.title || component.key,
  value(submission) {
    const cellValue = _get(submission, this.key, null);

    if (cellValue === null) {
      return '';
    }

    const rendered = this.component.asString(cellValue);
    if (cellValue !== rendered) {
      return {
        content: rendered,
        isHtml: true,
      };
    }

    return cellValue;
  },
});

export function useIsMountedRef(){
  const isMountedRef = React.useRef(null);
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => isMountedRef.current = false;
  });
  return isMountedRef;
}

export function getGlobalState(key) {
  let item = window.localStorage.getItem(key);
  if (item && (item !== 'undefined')) {
    return JSON.parse(window.localStorage.getItem(key));
  }
  return undefined;
}

export function getToken() {
  return window.localStorage.getItem('formioToken');
}

export function getSession(value, options = { token: false }) {
  const account = getGlobalState('account');
  if (account) {
    value = value || {};
    value.headers = value.headers || {};
    value.headers['x-account'] = account.account || '';
  }

  const currentSite = getGlobalState('currentSite');
  if (currentSite) {
    value = value || {};
    value.headers = value.headers || {};
    value.headers['x-site-id'] = currentSite.id || '';
  }

  if (options.token) {
    value.headers['x-jwt-token'] = getToken();
  }

  return value;
}

export function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return ((stickyValue !== null) && (stickyValue !== 'undefined'))
      ? JSON.parse(stickyValue)
      : defaultValue;
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

export function setTitle(title) {
  if (title) {
    document.title = title + ' - ' + 'Admin | Edifyd';
  } else {
    document.title = 'Admin | Edifyd';
  }
}

export function getName(submission, field = false) {
  let name = _get(submission, field || 'data.name', '');

  if (!name) {
    let name1 = _get(submission, 'data.firstname');
    let name2 = _get(submission, 'data.lastname');
    return [name1, name2].join(' ');
  }

  return name;
}


export function setColumnsWidth(columns) {
  if (columns.length > 6) {
    columns.forEach((column) => {
      column.width = 3;
    });
  }
  else {
    const columnsAmount = columns.length;
    const rowWidth = 12;
    const basewidth = Math.floor(rowWidth / columnsAmount);
    const remainingWidth = rowWidth - basewidth * columnsAmount;

    columns.forEach((column, index) => {
      column.width = (index < remainingWidth) ? (basewidth + 1) : basewidth;
    });
  }
}

export const stopPropagationWrapper = (fn) => (event) => {
  event.stopPropagation();
  fn();
};

export const debug = (msg) => {
  //console.log(msg);
}

export const log = (msg) => {
  throw new Error(msg);
}
