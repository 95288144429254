import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'

import React from 'react';
import { render } from 'react-dom'
import { Provider } from "react-redux";

import * as serviceWorker from './serviceWorker';

import store from './store'
import { initAuth, Formio, Components } from 'react-formio';
import App from './App';

import components from './components';
import { AppConfig } from './config';

import './index.css';

import { getGlobalState, getSession } from './utils';

Formio.setProjectUrl(AppConfig.projectUrl);
Formio.setBaseUrl(AppConfig.apiUrl);
Formio.setAuthUrl(AppConfig.runnerUrl + 'edifyd/user');
Formio.projectUrlSet = true;

//wrapFileRequestPromise
//wrapRequestPromise
//requestOptions
//requestResponse
//wrapStaticRequestPromise
//wrapFetchRequestPromise

Formio.registerPlugin({
  priority: 0,
  requestOptions: function (value, url) {
    //value.headers.append("Authorization", <Bearer Token loaded>);
	  console.log('REQUEST OPTIONS');
    value = getSession(value);
    value.headers['x-app'] = 'admin';
	  console.log(value);
    value.url = '/boo';
    value.uri = '/boo';
    value.baseUrl = 'https://boo.edifyd.com';
	  console.log(value);

    return value;
  },
});

Formio.registerPlugin({
  priority: 0,
  requestUrl: function (value, url) {
    console.log('MOD URL');
    console.log(value);
    console.log(url);
    return url;
  }
});

Formio.registerPlugin({
  priority: 0,
  wrapFetchRequestPromise: function (fetch, options) {
	  console.log('FETCH');
	  console.log(fetch);
	  console.log(options);
	  return fetch;
    //return value;
  }
});

Components.setComponents(components);

// Initialize the current user
store.dispatch(initAuth());

render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
