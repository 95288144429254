import React, {Component} from 'react';
import Login from './Login';
import { Link } from "react-router-dom";
import Register from './Register';
import {Route} from "react-router-dom";
//import {AppConfig} from '../../config';
import { AppNavbarBrand } from "@coreui/react";

import logo from "../../assets/img/brand/edifyd_icon-green-medium-clear.png";

import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row,
} from "reactstrap";

const Auth = class extends Component {
  render() {
    const {location} = this.props;
    return (location.pathname === '/auth') ?
      <div id="signin2-background-image">
        <div id="signin2-text" className="d-none d-md-block">
          <p id="signin2-text1">Get Onboarded and Work.</p>
          <p id="signin2-text2">
              <span>
                <AppNavbarBrand
                  id="copyright-logo"
                  full={{
                    src: logo,
                    width: 25,
                    height: 25,
                    alt: "Edifyd Logo"
                  }}
                />
              </span>
            Copyright © <span>2021</span>
          </p>
        </div>
        <Container>
          <Row>
            <Col className="col-md-5 offset-md-8">
              <CardGroup>
                <Card id="signin2-card" className="p-4">
                  <CardBody>
                    <AppNavbarBrand
                      id="signin2-logo"
                      full={{
                        src: logo,
                        width: 40,
                        height: 40,
                        alt: "Edifyd Logo"
                      }}
                    />
                    <h3>Welcome to</h3>
                    <h5>Please sign in</h5>

                    <Login />

                    <div id="signin2-create-forgot-btns">
                      <Link to="/register"><i className="fa fa-plus" /> First time user?</Link><br/>
                      <Link to="#"><i className="fa fa-exclamation-triangle" /> Forgot password</Link>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div> :
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <div className="panel panel-default card">
            <div className="panel-heading card-header" style={{paddingBottom: 0, borderBottom: 'none'}}>
              <ul className="nav nav-tabs" style={{borderBottom: 'none'}}>
              </ul>
            </div>
            <div className="panel-body card-body">
              <div className="row">
                <div className="col-lg-12">
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/register" component={Register} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>;
  }
}

export default Auth
