import { combineReducers } from "redux";
import { form, submission, submissions } from 'react-formio';
import { AppModule } from '../config';

const modules = {}
function* entries(obj) {
   for (let key of Object.keys(obj)) {
     yield [key, obj[key]];
   }
}

for (let [key, value] of entries(AppModule)) {
  if (typeof(value) == 'object') {
    modules[value.state] = combineReducers({
      form: form({ name: value.formId }),
      submission: submission({name: value.formId }),
      submissions: submissions({name: value.formId }),
    })
  } else {
    modules[key] = combineReducers({
      form: form({ name: value }),
      submission: submission({name: value }),
      submissions: submissions({name: value }),
    })
  }
}

export default modules;
