import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-formio';
import { push } from 'connected-react-router'
import {AppConfig, AuthConfig, AppModule} from '../../config';
import { setUser, saveSubmission, getSubmissions } from 'react-formio';

import _get from 'lodash/get';

const Login = class extends Component {
  render() {
    return (
      <Form {...this.props} onRender={this.onRender} />
    );
  }

  onRender = (test) => {
  }
}

const mapStateToProps = () => {
  return {
    //src: (AppConfig.runnerUrl ? AppConfig.runnerUrl + 'formapi' : AppConfig.projectUrl) + '/' + AuthConfig.login.form,
    src: AppConfig.projectUrl + AuthConfig.login.form,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    options: {
      'hooks': {
        'beforeSubmit': (submission, cb) => {
          dispatch(saveSubmission('app/login', submission, AppConfig.runnerUrl + 'edifyd/user/auth', (err, res) => {
            if (err) {
              return cb(err.error || err.err || 'Server: Unable to log in');
            }

            if (res && res.length) {
              var body = res[0].data;
              if (body && body.success == 'ok') {
                submission.data.status = body.status;
                submission.data.access = new Date();
                window.localStorage.setItem('user', JSON.stringify(body));
                return cb(null, submission);
              } else if (body && body.success == 'failed') {
                return cb(body.err);
              }
            }

            return cb(err.error || 'Unknown error');
          }));
        },
        'customValidation': (submission, cb) => {
          console.log('customValidation');
          cb(null, submission);
        }
      }
    },
    authUrl: AppConfig.runnerUrl + 'edifyd/user',
    onError: (a, b) => {
    },
    onAttach: (submission) => {
      console.log('onAttach');
    },
    onRender: (submission) => {
      console.log('onRender');
    },
    onFormLoad: (submission) => {
      console.log('onFormLoad');
    },
    onSubmitButton: (submission, b) => {
      console.log('onSubmitButton');
    },
    onSubmit: (submission) => {
      console.log('onSubmit');

      var query = {
        'data.group_type': 'site'
      };

      dispatch(getSubmissions(AppModule.group.formId, 1, query, AppModule.group.formUrl, function(err, groups) {
        window.localStorage.setItem('sites', JSON.stringify(groups));
        dispatch(setUser(submission));
        dispatch(push(AuthConfig.authState));
      }));

    },
    onSubmitDone: (submission) => {
      console.log('onSubmitDone');
      console.log(submission);
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
