import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-formio';
import { push } from 'connected-react-router'
import {AppConfig, AuthConfig} from '../../config';
import { setUser, saveSubmission } from 'react-formio';

const Login = class  extends Component {
  render() {
    return (
      <Form {...this.props} onRender={this.onRender} />
    );
  }

  onRender = (test) => {
  }
}

const mapStateToProps = () => {
  return {
    src: AppConfig.projectUrl + AuthConfig.adminlogin.form,
    baseUrl: 'httpsa://edifyd.com/',
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    options: {
      'hooks': {
        'beforeSubmit': (submission, cb) => {
          dispatch(saveSubmission('admin/login', submission, null, (err, res) => {
            if (res && res.length) {
              submission.data.status = res[0].data;
              submission.data.access = new Date();
            }
            cb(null, submission);
          }));
        },
        'customValidation': (submission, cb) => {
          console.log('customValidation');
          cb(null, submission);
        }
      }
    },
    onSubmit: (submission) => {
      console.log('onSubmit');
      dispatch(setUser(submission));
      dispatch(push(AuthConfig.authState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
